$(function(){

	$('.faqItem__plus').on('click', function(e) {
		e.preventDefault();		
		$(this).parent().find('.faqItem__answer').slideToggle('slow');
		$(this).parent().find('.faqItem__plus').toggleClass('minus');
	});

	$('.faqNext__link').click(function (e) {
		e.preventDefault();
		$(this).text($('.faqNext')
			.is(':visible') ? 'показать еще' : 'cвернуть');
				$('.faqNext').slideToggle('slow'); 

			 });


    $("#search").keyup(function(){
    	_this = this;    
	    $.each($("#mytable tbody tr"), function() {
	        if($(this).text().toLowerCase().indexOf($(_this).val().toLowerCase()) === -1) {
	            $(this).hide();
	        } else {
	            $(this).show();                
	        };
	    });
	});

	// Кнопка поделиться
	$('.rec').click(function(){
		window.open( $(this).attr("href"),'displayWindow', 'width=1170,height=600,left=200,top=100');
	    return false;
	});

	// Click Scroll
	$('.go-to').click(function(e){    
		var target = $(this).attr('href');
			$('html, body').animate({scrollTop: $(target).offset().top -40}, 1000);
				e.preventDefault();
		return false;
	});

	// Menu Humburger
	$('.humburger').click(function (e) {
		e.preventDefault();
		$('.humburger').toggleClass('mh_active');
		$('.menu-open').toggleClass('menuShow');
		$('body').toggleClass('hidden');
	});
	$('.mobileMenu li a').click(function (e) {
		$('.humburger').removeClass('mh_active');
		$('.menu-open').removeClass('menuShow');
		$('body').removeClass('hidden');
	});
	
});